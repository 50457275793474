  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @import url('https://fonts.googleapis.com/css2?family=Lobster&family=Merriweather+Sans:wght@300&display=swap');

  html,
  body {
    font-family: 'Merriweather Sans',
    sans-serif;
  }
 
  p{
      font-family: 'Lobster', cursive;
  }

 